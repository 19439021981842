<script setup>
import Multiselect from '@vueform/multiselect'

const IS_SSR = useNuxtApp().ssrContext

const emit = defineEmits(['onSelect'])
const props = defineProps(['modelValue'])
const countryCodeSelect = ref(null)

const {data: phoneCodesList} = await useAsyncData('phone-codes', async () =>
  $fetch(nuApiUrl('phone-codes', IS_SSR), {
    method: 'GET',
  }),
)

const formattedPhoneCodes =
  computed(() =>
    phoneCodesList.value?.data?.phone_codes?.map((item, index) => ({
      id: index + 1,
      text: `+${item.phone_code}`,
      flag: item.iso_code,
    })),
  ) || []

watch(
  formattedPhoneCodes,
  async () => {
    if (formattedPhoneCodes.value?.length > 0) {
      if (props.modelValue) {
        const selectedValue = formattedPhoneCodes.value?.find((item) => item.text === props.modelValue)
        countryCodeSelect.value = selectedValue?.id
      } else {
        countryCodeSelect.value = formattedPhoneCodes.value[0].id
      }
    }
  },
  {immediate: true},
)

function onCountryCodeSelect() {
  const selectedOption = formattedPhoneCodes.value?.find((item) => item.id === parseInt(countryCodeSelect.value))
  emit('onSelect', selectedOption)
}
</script>

<style lang="scss">
@import 'assets/css/components/global/nu-phone-code.css';
</style>

<template>
  <div class="nu-phone-code">
    <Multiselect
      :canClear="false"
      :canDeselect="false"
      :options="formattedPhoneCodes"
      :searchable="true"
      :v-bind="$attrs"
      id="nu-phone-code-id"
      data-testid="nuPhoneCode"
      label="text"
      trackBy="text"
      valueProp="id"
      clearOnSearch
      v-model="countryCodeSelect"
      @select="onCountryCodeSelect"
    >
      <template #singlelabel="{value}">
        <div class="multiselect-single-label has-icon">
          <span class="multiselect-single-label-flag">
            <NuFlagIcon :name="value.flag" />
          </span>
          <span class="multiselect-single-label-text">{{ value.text }}</span>
        </div>
      </template>
    </Multiselect>
  </div>
</template>
